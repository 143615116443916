import { oaService } from './index'

//数据折线图
function getLineChartData(params) {
  return oaService.post('/data/getDataLine', params)
}

//关键数据
function getDataNum(params) {
  return oaService.post('/data/getDataNum', params)
}

//获取所有用户
function getAllUser() {
  return oaService.get('/data/getUserList')
}

// 团队数据看板
function getTeamData(params) {
  return oaService.post('/data/getTeamData', params)
}

//转化数据看板
function getTransformData(params) {
  return oaService.post('/data/transform-data', params)
}

//转化数据看板
function getDataProgress(params) {
  return oaService.post('/data/getDataSchedule', params)
}

//用户目标值
function getUserTarget(params) {
  return oaService.get('/data/getUserDayGoal', {params})
}


export default {
  getLineChartData,
  getDataNum,
  getAllUser,
  getTeamData,
  getTransformData,
  getDataProgress,
  getUserTarget,
}